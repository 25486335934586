import React from 'react';

export function CTA() {
  return (
    <a
      className="fixed bottom-[2vh] right-[2vh] rounded-full bg-[#07A884] p-2 font-bold text-black"
      href="https://wa.me/5491163729511"
    >
      <h1 className="float-left p-2 text-lg">&nbsp;&nbsp;WhatsApp</h1>
      <i className="fa fa-whatsapp fa-2xl p-2 py-5" aria-hidden="true" />
    </a>
  );
}
