import React from 'react';
import Dropdown from './Dropdown';

type Props = {
  title: string;
  setShowEvent: (showEvent: boolean) => void;
};

function Header(props: Props): React.ReactElement {
  return (
    <header className="navbar fixed top-0 left-0 right-0 bg-zinc-900">
      <div className="flex-none">
        <button className="btn-ghost btn-square btn">
          <img
            className="float-left mx-3 h-8 py-0.5"
            src="/images/logo.png"
            alt="Logo de Marinas Bay"
          />
        </button>
      </div>
      <div className="flex-1">
        <a href="/" className="btn-ghost btn text-xl normal-case">
          <h1 className="float-left my-2 bg-zinc-900 text-left text-2xl font-bold text-zinc-300">
            {props.title}
          </h1>
        </a>
      </div>
      <Dropdown setShowEvent={props.setShowEvent} />
    </header>
  );
}

export default Header;
