import React from 'react';

export function SpecialEvent(props: {
  body: string;
  title: string;
  checked: boolean;
  setChecked: (checked: boolean) => void;
}) {
  return (
    <div className="overflow-hidden">
      <input
        type="checkbox"
        id="special-event-modal"
        className="modal-toggle"
        checked={props.checked}
        onChange={() => {}}
      />
      <div className="modal w-screen">
        <div className="modal-action">
          <label
            hidden
            htmlFor="special-event-modal"
            className="bg-zinc-400 text-black"
          />
          <div className="rounded-box absolute top-[50%] left-[10vw] max-h-[80vmin] min-h-[80vmin] min-w-[80vmin] max-w-[80vmin] -translate-y-[40vmin] transform sm:top-[10vh] sm:left-[50%] sm:translate-y-0 sm:-translate-x-[40vmin] sm:transform sm:transform">
            <div className="absolute top-0 right-0 m-2 h-9 w-9 rounded-full bg-black bg-opacity-80 p-1">
              <i
                className="fa-solid fa-times cursor-pointer text-zinc-100"
                onClick={() => {
                  props.setChecked(false);
                }}
              />
            </div>
            <Promos />
          </div>
        </div>
      </div>
    </div>
  );
}

function Promos() {
  return (
    <div className="w-[100%] snap-x snap-mandatory overflow-x-auto">
      <div className="grid w-[200%] grid-cols-2 gap-1">
        <div className="snap-start snap-always">
          <img
            src="./images/fiestas.jpg"
            alt="Fiestas 2022"
            className="rounded-box"
          />
        </div>
      </div>
    </div>
  );
}
