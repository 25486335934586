import React from 'react';
import {CTA} from './CTA';
import Section from './Section';
import {content} from './content';

export function Main() {
  const searchString =
    'J96F+FR Rincón de Milberg, Buenos Aires Province, Argentina';
  const encodedSearchString = encodeURIComponent(searchString);
  return (
    <main className="scrolling-touch h-[calc(100%)] w-screen snap-y snap-mandatory overflow-y-auto overflow-x-hidden scroll-smooth">
      {content.map(({text, title, image}) => (
        <Section
          key={title}
          sectionTitle={`${title}`}
          sectionImage={`./images/${image}`}
        >
          <p className="mt-2 pr-8 pb-8 text-left text-white">{text}</p>
        </Section>
      ))}
      <section
        id="carta"
        className="grid h-full w-screen shrink-0 snap-end snap-always grid-cols-1 content-end overflow-hidden bg-black bg-cover bg-center bg-no-repeat"
        style={{backgroundImage: 'url(/images/3.jpg)'}}
      >
        <div className="mx-auto w-2/3 sm:w-1/4">
          <div id="menu1" className="carousel-item w-full">
            <a href="/images/menu1.jpg" className="w-full">
              <img src="/images/menu1.jpg" className="w-full" alt="carta 1" />
            </a>
          </div>
        </div>
        <div className="content-end">
          <div className="bg-gradient-to-b from-transparent to-black opacity-60">
            <br />
            <br />
          </div>
          <div className="bg-black bg-opacity-60 px-4 text-white">
            <div className="mx-auto w-full pb-4 sm:w-1/2">
              <div className="text-center text-white">
                *contamos con menú sin TACC, libre de gluten, apto celíacos
              </div>
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
      </section>
      <section
        className="grid h-full w-screen shrink-0 snap-end snap-always grid-cols-1 content-end overflow-hidden bg-black bg-cover bg-center bg-no-repeat"
        style={{backgroundImage: 'url(/images/2.jpg)'}}
      >
        <div className="mx-auto w-2/3 sm:w-1/4">
          <div id="menu1" className="carousel-item w-full">
            <a href="/images/menu2.jpg" className="w-full">
              <img src="/images/menu2.jpg" className="w-full" alt="carta 2" />
            </a>
          </div>
        </div>
        <div className="content-end">
          <div className="bg-gradient-to-b from-transparent to-black opacity-60">
            <br />
            <br />
          </div>
          <div className="bg-black bg-opacity-60 px-4 text-white">
            <div className="mx-auto w-full pb-4 sm:w-1/2">
              <div className="text-center text-white">
                *contamos con menú sin TACC, libre de gluten, apto celíacos
              </div>
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
      </section>
      <section
        id="mapa"
        className="h-full w-screen snap-end snap-always content-end overflow-hidden bg-black bg-cover bg-center bg-no-repeat"
      >
        <iframe
          title={searchString}
          src={`https://maps.google.com/maps?output=embed&t=k&q=${encodedSearchString}`}
          className="mx-auto h-full w-full bg-black sm:h-[50vw] sm:w-1/2"
        />
      </section>

      <CTA />
    </main>
  );
}
