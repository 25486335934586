import React from 'react';
import Social from './Social';

type Props = {
  searchString: string;
  userName: string;
};

export function Footer(props: Props) {
  return (
    <footer className="w-screen bg-black bg-cover text-center text-white">
      <div className="mx-auto flex w-full flex-col bg-black p-4 sm:w-1/2">
        <div className="bg-black">
          <ul className="flex justify-evenly">
            {['instagram', 'facebook', 'twitter'].map((social) => (
              <li key={social}>
                <Social platformName={social} userName={props.userName} />
              </li>
            ))}
          </ul>
        </div>
        <div className="m-8 bg-black">
          <p>
            <a href="tel:+5491163729511">Telefono: +54 9 11 6372-9511</a>
          </p>
          <p>
            <a href="mailto:hernanlandereche@gmail.com" className="text-white">
              Email: hernanlandereche@gmail.com
            </a>
          </p>
          <br />
          <address>
            <p>Marinas Bay</p>
            <p>Marinas Golf</p>
            <p>Rincon de Milberg, Buenos Aires</p>
            <p>Argentina</p>
          </address>
          <br />
          <br />
          <br />
        </div>
      </div>
    </footer>
  );
}
