import React from 'react';
import Header from './Header';
import './App.css';
import {SpecialEvent} from './SpecialEvent';
import {Main} from './Main';
import {Footer} from './Footer';

function App() {
  const [showEvent, setShowEvent] = React.useState(false);
  const overflow = showEvent ? 'overflow-y-hidden' : 'overflow-y-auto';
  return (
    <div className={`App ${overflow} overflow-x-hidden`}>
      <Header title="Marinas Bay" setShowEvent={setShowEvent} />
      <Main />
      <Footer
        searchString="J96F+FR Rincón de Milberg, Buenos Aires Province, Argentina"
        userName="marinasbay"
      />
      <SpecialEvent
        title=""
        body=""
        checked={showEvent}
        setChecked={setShowEvent}
      />
    </div>
  );
}

export default App;
