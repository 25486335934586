import React from 'react';
import {content} from './content';

type Props = {
  setShowEvent: (showEvent: boolean) => void;
};

function Dropdown(props: Props): React.ReactElement {
  return (
    <div className="dropdown-end dropdown float-right bg-zinc-900">
      <label
        tabIndex={0}
        className="btn-ghost btn rounded-none hover:bg-zinc-900"
      >
        <i
          className="fa-solid fa-bars fa-2xl rounded-none text-zinc-400"
          aria-hidden="true"
        />
      </label>
      <ul
        tabIndex={0}
        className="dropdown-content menu rounded-none bg-zinc-900 text-zinc-400 shadow-md"
      >
        {content
          .filter(({title, menuTitle}) => Boolean(menuTitle))
          .map(({title, menuTitle}) => (
            <li key={menuTitle}>
              <a href={`#${encodeURI(title)}`}>{menuTitle}</a>
            </li>
          ))}
        <li>
          <a href="#carta">Carta</a>
        </li>
        <li>
          <a href="#mapa">Mapa</a>
        </li>
        <li>
          <button
            className="list-item text-left"
            onClick={() => {
              props.setShowEvent(true);
            }}
          >
            Cena Navideña / Año&nbsp;Nuevo&nbsp;2022/2023
          </button>
        </li>
      </ul>
    </div>
  );
}

export default Dropdown;
