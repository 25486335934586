import React from 'react';

type Props = {
  platformName: string;
  userName: string;
};

function Social(props: Props) {
  return (
    <a
      href={`https://www.${props.platformName}.com/${props.userName}`}
      className="text-white"
    >
      <i className={`fa fa-${props.platformName} fa-3x`} aria-hidden="true" />
    </a>
  );
}

export default Social;
