import React, {type ReactNode} from 'react';

export default function Section(props: {
  sectionTitle: string;
  sectionImage: string;
  children?: ReactNode;
}) {
  return (
    <section
      id={encodeURI(props.sectionTitle)}
      className="z-0 grid h-full snap-end snap-always content-end overflow-hidden bg-cover bg-center bg-no-repeat"
      style={{backgroundImage: `url(${props.sectionImage})`}}
    >
      <div className="content-end">
        <div className="bg-gradient-to-b from-transparent to-black opacity-60">
          <br />
          <br />
        </div>
        <div className="bg-black bg-opacity-60 px-4 text-white">
          <div className="mx-auto w-full pb-4 sm:w-1/2">
            <h2 className="text-left text-2xl font-bold leading-tight tracking-wider text-white">
              {props.sectionTitle}
            </h2>
            {props.children}
          </div>
        </div>
      </div>
    </section>
  );
}
