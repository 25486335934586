import React from 'react';

type Content = {
  menuTitle?: string;
  title: string;
  text: string | React.ReactElement;
  image: string;
};

export const content: Content[] = [
  {
    menuTitle: 'Inicio',
    title: 'En el corazón del Delta',
    text: 'Marinas Bay es el único restaurante flotante sobre el Río Luján, rodeado de naturaleza con vistas privilegiadas. Ubicado frente a la reserva natural de tigre, dentro del barrio náutico MARINAS GOLF.',
    image: 'mb.jpeg',
  },
  {
    title: 'Marisquería sobre el Río',
    text: 'Disfrutá de nuestra propuesta gastronómica que ofrece sabores, texturas y colores en un marco natural, con excelentes vistas panorámicas del río y nuestra Marina. Experimentá la calma de nuestros paisajes y la aventura de estar inmerso en la naturaleza típica de las islas.',
    image: '13.jpg',
  },
  {
    menuTitle: 'Accesibilidad',
    title: 'Visítanos por río o por tierra',
    text: 'Contamos con amarras de cortesía para lanchas y estacionamiento exclusivo para autos.',
    image: '1.jpg',
  },
  {
    title: 'Eventos',
    text: 'Organizá tu evento privado o empresarial sobre el río en MARINAS BAY.',
    image: '6.jpg',
  },
  {
    menuTitle: 'Horarios',
    title: 'Horarios',
    text: (
      <>
        Sábados de 12 a 23.30hs
        <br />
        Domingo de 12 a 18hs
      </>
    ),
    image: '7.jpg',
  },
];

export default content;
